/* CSS */
import "@assets/scss/all.scss";

/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons); // loads the Icon plugin

// Init jquery cookies
$('#cookie-consent').cookies();

//Add Class to headings, which have strong-childs to style the unbolded parts in thin, if they are basically light f.e.
$('h1, h2, h3, h4, h5, h6').each(function() {
    if ($(this).find('strong').length > 0) {
        $(this).addClass('has-strong');
    }
});

function detectMobileDevice() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    console.log(userAgent);
    $(".devTweakInfo .console").html(userAgent);

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        document.body.classList.add('mobile-device');
        document.body.classList.add('ios-device');

        // Zusätzliche Erkennung für iPads
        if (/iPad/.test(userAgent)) {
            document.body.classList.add('tablet-device');
        }

        function isAddressBarAtTop() {
            const heightWithoutBar = window.screen.height - 50; // ungefährer Wert für die Adressleiste
            const heightWithBar = window.innerHeight;

            return heightWithBar < heightWithoutBar;
        }

        function handleAddressBarPosition() {
            if (isAddressBarAtTop()) {
                console.log('adress bar is on top');
                $(".devTweakInfo .console").html(userAgent+' <br>adress bar is on top');
                document.body.classList.add('address-bar-top');
                document.body.classList.remove('address-bar-bottom');
            } else {
                $(".devTweakInfo .console").html(userAgent+' <br>adress bar is on bottom or none found');
                console.log('adress bar is on bottom or none found');
                document.body.classList.add('address-bar-bottom');
                document.body.classList.remove('address-bar-top');
            }
        }

        // Initiale Überprüfung
        handleAddressBarPosition();
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
        document.body.classList.add('mobile-device');
        document.body.classList.add('android-device');

        // Zusätzliche Erkennung für Android-Tablets
        var isTablet = /android/i.test(userAgent) && !/mobi/i.test(userAgent);
        if (isTablet) {
            document.body.classList.add('tablet-device');
        }
    }
    // Generic mobile device detection
    else if (/Mobi|Android/i.test(userAgent)) {
        document.body.classList.add('mobile-device');

        // Zusätzliche generische Erkennung für Tablets
        var isGenericTablet = /Tablet|iPad/i.test(userAgent) || (window.innerWidth >= 768 && window.innerHeight >= 1024);
        if (isGenericTablet) {
            document.body.classList.add('tablet-device');
        }
    }
}

detectMobileDevice();

$(document).ready(function(){

    $("body").addClass("document-ready");

    $('#portfolio-slideshow').on('click', function(e) {
        if (!$('.arrow-navigation').is(':visible')) {
            var $slideshow = UIkit.slideshow($(this));
            var slideshowWidth = $(this).width();
            var clickX = e.pageX - $(this).offset().left;

            if (clickX < slideshowWidth / 2) {
                $slideshow.show('previous');
            } else {
                $slideshow.show('next');
            }
        }
    });

    // Überprüfen, ob eine Slideshow im DOM vorhanden ist
    if ($('[uk-slideshow]').length > 0) {
        console.log('slideshow found');
        var $slideshow = UIkit.slideshow($('[uk-slideshow]'));

        //Bei Tastendruck überprüfen, ob nach links oder rechts navigiert werden soll
        $(document).on('keydown', function(e) {
            if (e.key === "ArrowLeft") {
                // Linke Pfeiltaste gedrückt
                $slideshow.show('previous');
            } else if (e.key === "ArrowRight") {
                // Rechte Pfeiltaste gedrückt
                $slideshow.show('next');
            }
        });

        // Event für das Weiterswipen und Navigation
        UIkit.util.on('#portfolio-slideshow', 'beforeitemhide', function(event, next) {
            var portfolioSlideshow = $("#portfolio-slideshow");
            //reset class for long intro animation, after navigating through slideshow
            if (portfolioSlideshow.length > 0) {
                console.log('navigation through slideshow recognized');
                $(portfolioSlideshow).find("div.first-long-intro-animation").removeClass('first-long-intro-animation');
            }
        });
    }



    /*
    // Anzahl der Slides ermitteln
    var totalSlides = $('#portfolio-slideshow .uk-slideshow-items > div').length;

    // Variable zum Speichern des vorherigen Index
    var prevIndex = 0;

    // Funktion zur Überprüfung des aktuellen Slides
    function checkSlideNavigation(currentIndex) {
        var currentSlide = $('#portfolio-slideshow .uk-slideshow-items > div').eq(currentIndex - 1);

        // Überprüfen, ob der aktuelle Slide das "data-nextgallery"-Attribut hat und nach rechts geswiped wurde
        if (currentIndex === totalSlides && currentSlide.data("nextgallery") !== undefined && currentIndex > prevIndex) {
            window.location.href = currentSlide.data("nextgallery");
        }

        // Überprüfen, ob der aktuelle Slide das "data-prevgallery"-Attribut hat und nach links geswiped wurde
        if (currentIndex === 1 && currentSlide.data("prevgallery") !== undefined && currentIndex < prevIndex) {
            window.location.href = currentSlide.data("prevgallery");
        }

        // Update prevIndex
        prevIndex = currentIndex;
    }

    // Event für das Weiterswipen und Navigation hinzufügen
    UIkit.util.on('#portfolio-slideshow', 'beforeitemshow', function(event, next) {
        var currentIndex = next.index + 1; // next.index ist nullbasiert, also +1
        checkSlideNavigation(currentIndex);
    });*/
    

});
/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}